import React, {useState, useEffect, useRef, MouseEvent, TouchEvent} from "react";
import VideoBackground from "./VideoBackground";

export default function Home() {
    const [transformParams, setTransformParams] = useState({rx: 0, ry: 0, tx: 0, ty: 0, z: 1})
    const ref: any = useRef(null)

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));


    const handleMove = (event: MouseEvent | TouchEvent) => {
        const X = ref.current.clientWidth
        const Y = ref.current.clientHeight
        const x = event.clientX - (X / 2)
        const y = event.clientY - (Y / 2)

        const rx = x * 20 / (X / 2)
        const ry = y * 20 / (Y / 2)

        const tx = rx / 3;
        const ty = ry / 3;

        const z = 1;

//        if (isSafari) {
//            setTransformParams({tx, ty, rx:0, ry: 0, z})
//            return;
//        }

        setTransformParams({tx, ty, rx, ry, z})
    }

    const {rx, ry, tx, ty, z} = transformParams

    return (
        <div className="home" onMouseMove={handleMove} onTouchMove={handleMove} ref={ref}>
            <VideoBackground/>
            <div className="logo"
                style={{transform: `rotateX(${-ry}deg) rotateY(${rx}deg) translateX(${tx}em) translateY(${ty}em) translateZ(3em)  scale(0.3)`}}></div>
            <div className="details">
                <h1 className="name">Pauline Miele</h1>
                <h2 className="description">Développeuse Front End</h2>
            </div>
        </div>
    );
}

/*


*/
