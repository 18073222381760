import React from "react";

export default function Contact() {
    return (
        <div className="contact">
            <p>📨 <a href="mailto:pauline.miele@gmail.com" target="_blank">pauline.miele@gmail.com</a></p>
            <p>💻 <a href="https://github.com/p-mh" target="_blank">@p-mh</a></p>
            <p>📍<a
                href="https://www.google.com/maps/place/94200+Ivry-sur-Seine/@48.8125114,2.3784823,15z/data=!3m1!4b1!4m5!3m4!1s0x47e673b5023235ad:0x5dc8118d7cbf704f!8m2!3d48.813055!4d2.38822"
                target="_blank">Ivry-sur-Seine</a>
            </p>
        </div>
    );
}
