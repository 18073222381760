import React, {useState, useEffect} from "react";

import Home from "./components/Home";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import Switch from "./ds/Switch";

import changeColorMode from "./utils/changeColorMode";
import {setLocalStorage, getFromLocalStorage} from "./utils/localStorage";

import "./style/base.css";
import "./style/app.css";

export default function App() {
    const isColorModeDarkFromStorage = getFromLocalStorage("isColorModeDark");
    const isDarkUserSystem = window.matchMedia(
        "(prefers-color-scheme: dark)"
    ).matches;
    const [isColorModeDark, setState] = useState(
        isColorModeDarkFromStorage
            ? isColorModeDarkFromStorage === "true"
            : isDarkUserSystem
    );

    const useColorMode = (isColorModeDarkFromParam = null) => {
        const isCurrentColorModeLight = isColorModeDarkFromParam || isColorModeDark;
        changeColorMode(isCurrentColorModeLight);
    };

    useEffect(() => {
        useColorMode();
    });

    const updateColorMode = () => {
        const newIsColorModeLisght = !isColorModeDark;
        setState(newIsColorModeLisght);
        setLocalStorage("isColorModeDark", newIsColorModeLisght.toString());
    };

    const markdown = `Just a link: https://reactjs.com.`

    return (
        <div className="page">

            <Switch
                isChecked={isColorModeDark}
                onChange={updateColorMode}
                aria-hidden="true"
            />
            <Home/>
            <Contact/>

        </div>
    );
}
