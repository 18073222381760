import React from 'react';

const video = require("../images/background.mp4");

function VideoBackground() {

    return (
        <video autoPlay loop playsInline muted>
            <source src={video} type="video/mp4"/>
        </video>
    );
}

export default VideoBackground;