const setColorVariable = (colorProperty: string, colorVariable: string) => {
    const store = document.querySelector(":root");
    if (!store) {
        return
    }
    const value = getComputedStyle(store);

    // TODO fix that !
    // @ts-ignore
    root.style.setProperty(colorProperty, value.getPropertyValue(colorVariable));
};

const changeColorMode = (isColorModeDark: boolean) => {
    if (isColorModeDark) {
        setColorVariable("--color-primary", "--color-dark");
        setColorVariable("--color-secondary", "--color-light");
        setColorVariable("--color-top-gradient", "--color-black");
        // @ts-ignore
        root.style.setProperty("--video-brighness", "0.5");
        return;
    }
    setColorVariable("--color-primary", "--color-light");
    setColorVariable("--color-secondary", "--color-dark");
    setColorVariable("--color-top-gradient", "--color-white");
    // @ts-ignore
    root.style.setProperty("--video-brighness", "1.5");
};

export default changeColorMode;
