import React from "react";
import "./switch.css";

type Props = {
  isChecked: boolean,
  onChange: () => void,
}

export default function Switch({ isChecked, onChange, ...props }: Props) {
  return (
    <div className="color-switch">
      <label className="switch">
        <input
          type="checkbox"
          checked={!!isChecked}
          onChange={onChange}
          {...props}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
